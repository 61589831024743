import * as React from "react"
import Layout from "../components/layout"
import { Container, Box, Heading } from "../components/ui"
import { Helmet } from "react-helmet"

export default function Privacy() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | Privacy Policy</title>
        <link rel="canonical" href="https://aikate.eu/privacypolicy" />
        <meta
          name="Privacy Policy"
          content="Software development, IoT solutions, Privacy Policy"
        />
        <meta name="description" content="Privacy Policy"></meta>
      </Helmet>
      <Box paddingY={5}>
        <Container width="narrow">
          <Heading as="h1" className="pt-24">
            Privacy Policy
          </Heading>
          <p>
            Your privacy is important to us. It is aiKATE&#39;s policy to
            respect your privacy and comply with any applicable law and
            regulation regarding any personal information we may collect about
            you, including across our website,{" "}
            <a href="https://www.aikate.eu">https://www.aikate.eu</a>, and other
            sites we own and operate.{" "}
          </p>
          <p>
            This policy is effective as of 12 July 2022 and was last updated on
            12 July 2022.{" "}
          </p>
          <h3 className="inline-block text-2xl font-bold tracking-tight pt-4 pb-2">
            Information We Collect
          </h3>
          <p>
            Information we collect includes both information you knowingly and
            actively provide us when using or participating in any of our
            services and promotions, and any information automatically sent by
            your devices in the course of accessing our products and services.{" "}
          </p>
          <h4 className="inline-block text-2xl font-bold tracking-tight pt-4">
            Log Data
          </h4>
          <p>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, other details about your visit, and
            technical details that occur in conjunction with any errors you may
            encounter.{" "}
          </p>
          <p>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.{" "}
          </p>
          <h4 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Legitimate Reasons for Processing Your Personal Information
          </h4>
          <p>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.{" "}
          </p>
          <h4 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Collection and Use of Information
          </h4>
          <p>
            We may collect personal information from you when you do any of the
            following on our website:{" "}
          </p>
          <ul className="list-disc list-inside">
            <li>Use a mobile device or web browser to access our content</li>
            <li>
              Contact us via email, social media, or on any similar technologies
            </li>
            <li>When you mention us on social media</li>
          </ul>
          <p>
            We may collect, hold, use, and disclose information for the
            following purposes, and personal information will not be further
            processed in a manner that is incompatible with these purposes:{" "}
          </p>
          <ul className="list-disc list-inside">
            <li>
              to enable you to customise or personalise your experience of our
              website{" "}
            </li>
            <li>to contact and communicate with you</li>
            <li>
              for analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms
            </li>
            <li>
              for advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you
            </li>
            <li>
              to enable you to access and use our website, associated
              applications, and associated social media platforms
            </li>
          </ul>
          <p>
            Please be aware that we may combine information we collect about you
            with general information or research data we receive from other
            trusted sources.{" "}
          </p>
          <h4 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Security of Your Personal Information
          </h4>
          <p>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use, or modification.{" "}
          </p>
          <p>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure, and no one can guarantee absolute data
            security. We will comply with laws applicable to us in respect of
            any data breach.{" "}
          </p>
          <p>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services.{" "}
          </p>
          <h4 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            How Long We Keep Your Personal Information
          </h4>
          <p>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. If your personal
            information is no longer required, we will delete it or make it
            anonymous by removing all details that identify you.{" "}
          </p>
          <p>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.{" "}
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Cookie Policy
          </h3>
          <p>
            We use cookies to learn how you interact with our site and to
            improve your user experience. Cookies remember your preferences so
            that you do not have to repeatedly make choices when visiting our
            website.
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Cookies
          </h3>
          <p>
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you
            visit our websites, we may collect information from you
            automatically through cookies or similar technology.
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            How do we use cookies?
          </h3>
          <p>
            aiKATE uses cookies in a range of ways to improve your experience on
            our website, including:
            <li>Understanding how you use our website</li>
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            What types of cookies do we use?
          </h3>
          <p>
            There are a number of different types of cookies, however, our
            website uses:
            <li>
              Functionality: aiKATE uses these cookies so that we recognize you
              on our website and remember your previously selected preferences.
              These could include what language you prefer and location you are
              in. A mix of first-party and third-party cookies are used.
            </li>
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            How to manage cookies?
          </h3>
          <p>
            You can set your browser not to accept cookies, and the above
            website tells you how to remove cookies from your browser. However,
            in a few cases, some of our website features may not function as a
            result.
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Children’s Privacy
          </h3>
          <p>
            We do not aim any of our products or services directly at children
            under the age of 13, and we do not knowingly collect personal
            information about children under 13.{" "}
          </p>
          <h3 class="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            International Transfers of Personal Information
          </h3>
          <p>
            The personal information we collect is stored and/or processed where
            we or our partners, affiliates, and third-party providers maintain
            facilities. Please be aware that the locations to which we store,
            process, or transfer your personal information may not have the same
            data protection laws as the country in which you initially provided
            the information. If we transfer your personal information to third
            parties in other countries: (i) we will perform those transfers in
            accordance with the requirements of applicable law; and (ii) we will
            protect the transferred personal information in accordance with this
            privacy policy.{" "}
          </p>
          <h3 class="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Your Rights and Controlling Your Personal Information
          </h3>
          <p>
            You always retain the right to withhold personal information from
            us, with the understanding that your experience of our website may
            be affected. We will not discriminate against you for exercising any
            of your rights over your personal information. If you do provide us
            with personal information you understand that we will collect, hold,
            use and disclose it in accordance with this privacy policy. You
            retain the right to request details of any personal information we
            hold about you.{" "}
          </p>
          <p>
            If we receive personal information about you from a third party, we
            will protect it as set out in this privacy policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.{" "}
          </p>
          <p>
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time.
            We will provide you with the ability to unsubscribe from our
            email-database or opt out of communications. Please be aware we may
            need to request specific information from you to help us confirm
            your identity.{" "}
          </p>
          <p>
            If you believe that any information we hold about you is inaccurate,
            out of date, incomplete, irrelevant, or misleading, please contact
            us using the details provided in this privacy policy. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading, or out of date.{" "}
          </p>
          <p>
            If you believe that we have breached a relevant data protection law
            and wish to make a complaint, please contact us using the details
            below. We will promptly investigate your complaint and respond to
            you.{" "}
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Limits of Our Policy
          </h3>
          <p>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.{" "}
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Changes to This Policy
          </h3>
          <p>
            At our discretion, we may change our privacy policy to reflect
            updates to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this
            privacy policy, we will post the changes here at the same link by
            which you are accessing this privacy policy.{" "}
          </p>
          <h3 className="inline-block font-bold text-2xl tracking-tight pt-4 pb-2">
            Contact Us
          </h3>
          <p>
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:
          </p>
          <a
            href="mailto:info@aikate.hr"
            className="pt-4 hover:text-[#005BEA] text-xl"
          >
            info@aikate.hr
          </a>
        </Container>
      </Box>
    </Layout>
  )
}
